import React, { useMemo, useReducer } from 'react';

import { element } from 'prop-types';

import Context from './context';
import reducer, { initialState } from './reducer';
import {
  SET_PRELOADING,
  TOGGLE_MENU,
  SET_DESKTOP_BACKGROUND,
  TOGGLE_ABOUT_MODAL,
  TOGGLE_CONTACT_US_MODAL,
  SET_SLEEPING_MODE,
  TOGGLE_TEAM_MODAL,
  SET_GLOBAL_ZINDEX,
  TOGGLE_OUR_SERVICES_MODAL,
  TOGGLE_TRASH_MODAL,
  TOGGLE_MINESWEEPER_MODAL,
  TOGGLE_PAINT_MODAL,
  TOGGLE_ANTIVIRUS_MODAL,
  SET_CLOSE_ALL_MODALS,
  TOGGLE_SEARCH_MODAL,
  TOGGLE_FOOTER,
  TOGGLE_PROJECTS_MODAL,
  TOGGLE_WEB3COMPASS_MODAL,
  TOGGLE_THRUPENNY_MODAL,
  TOGGLE_COYOTE_MODAL,
  TOGGLE_CLOVERCOIN_MODAL,
  TOGGLE_GOGOFINANCE_MODAL,
  TOGGLE_MOMENTO_MODAL,
  TOGGLE_JUSTYOURS_MODAL,
  TOGGLE_YOP_MODAL,
  TOGGLE_MORTAL_METACITIES,
  TOGGLE_KONSTA_MODAL,
  TOGGLE_PARTNERS_MODAL,
  TOGGLE_NFTLOOTS_MODAL,
  TOGGLE_CHUBCAY_MODAL,
  SET_MAXIMIZE_CONTACTS_MODAL,
  SET_MAXIMIZE_PROJECTS_MODAL,
  SET_MAXIMIZE_COMPASS_PROJECT_MODAL,
  SET_MAXIMIZE_YOP_PROJECT_MODAL,
  SET_MAXIMIZE_THRUPENNY_PROJECT_MODAL,
  SET_MAXIMIZE_MORTAL_PROJECT_MODAL,
  SET_MAXIMIZE_MOMENTO_PROJECT_MODAL,
  SET_MAXIMIZE_KONSTA_PROJECT_MODAL,
  SET_MAXIMIZE_JUSTYOURS_PROJECT_MODAL,
  SET_MAXIMIZE_GOGO_PROJECT_MODAL,
  SET_MAXIMIZE_COYOTE_PROJECT_MODAL,
  SET_MAXIMIZE_CLOVERCOIN_PROJECT_MODAL,
  SET_MAXIMIZE_ABOUTUS_MODAL,
  SET_MAXIMIZE_MINESWEEPER_MODAL,
  SET_MAXIMIZE_PARTNERS_MODAL,
  SET_MAXIMIZE_OURSERVICES_MODAL,
  SET_MAXIMIZE_PAINT_MODAL,
  SET_MAXIMIZE_TEAMS_MODAL,
  SET_MAXIMIZE_TRASH_MODAL,
  SET_UNMAXIMIZE_ALL_MODALS,
  SET_MAXIMIZE_NFTLOOTS_MODAL,
  SET_MAXIMIZE_CHUBCAY_MODAL,
} from './types';

function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  function togglePaintModal(payload) {
    dispatch({
      type: TOGGLE_PAINT_MODAL,
      payload,
    });
  }

  function setPreloader(payload) {
    dispatch({
      type: SET_PRELOADING,
      payload,
    });
  }

  function toggleMenu(payload) {
    dispatch({
      type: TOGGLE_MENU,
      payload,
    });
  }

  function setActiveBg(payload) {
    dispatch({
      type: SET_DESKTOP_BACKGROUND,
      payload,
    });
  }

  function toggleAboutModal(payload) {
    dispatch({
      type: TOGGLE_ABOUT_MODAL,
      payload,
    });
  }

  function toggleContactUsModal(payload) {
    dispatch({
      type: TOGGLE_CONTACT_US_MODAL,
      payload,
    });
  }

  function setSleepingMode(payload) {
    dispatch({
      type: SET_SLEEPING_MODE,
      payload,
    });
  }

  function toggleTeamModal(payload) {
    dispatch({
      type: TOGGLE_TEAM_MODAL,
      payload,
    });
  }

  function setGlobalZindex() {
    dispatch({
      type: SET_GLOBAL_ZINDEX,
    });
  }

  function toggleServicesModal(payload) {
    dispatch({
      type: TOGGLE_OUR_SERVICES_MODAL,
      payload,
    });
  }

  function toggleTrashModal(payload) {
    dispatch({
      type: TOGGLE_TRASH_MODAL,
      payload,
    });
  }

  function toggleMinesweeperModal(payload) {
    dispatch({
      type: TOGGLE_MINESWEEPER_MODAL,
      payload,
    });
  }

  function toggleAntivirusModal(payload) {
    dispatch({
      type: TOGGLE_ANTIVIRUS_MODAL,
      payload,
    });
  }

  function setAllModalsClose() {
    dispatch({
      type: SET_CLOSE_ALL_MODALS,
    });
  }

  function setUnMaximizeAllModals() {
    dispatch({
      type: SET_UNMAXIMIZE_ALL_MODALS,
    });
  }

  function toggleSearchModal(payload) {
    dispatch({
      type: TOGGLE_SEARCH_MODAL,
      payload,
    });
  }
  function toggleFooter(payload) {
    dispatch({
      type: TOGGLE_FOOTER,
      payload,
    });
  }
  function togglePartnersModal(payload) {
    dispatch({
      type: TOGGLE_PARTNERS_MODAL,
      payload,
    });
  }
  function toggleProjectsModal(payload) {
    dispatch({
      type: TOGGLE_PROJECTS_MODAL,
      payload,
    });
  }
  function toggleWeb3CompassModal(payload) {
    dispatch({
      type: TOGGLE_WEB3COMPASS_MODAL,
      payload,
    });
  }
  function toggleThrupennyModal(payload) {
    dispatch({
      type: TOGGLE_THRUPENNY_MODAL,
      payload,
    });
  }
  function toggleCoyoteModal(payload) {
    dispatch({
      type: TOGGLE_COYOTE_MODAL,
      payload,
    });
  }
  function toggleClovercoinModal(payload) {
    dispatch({
      type: TOGGLE_CLOVERCOIN_MODAL,
      payload,
    });
  }
  function toggleGogofinanceModal(payload) {
    dispatch({
      type: TOGGLE_GOGOFINANCE_MODAL,
      payload,
    });
  }
  function toggleMomentoModal(payload) {
    dispatch({
      type: TOGGLE_MOMENTO_MODAL,
      payload,
    });
  }
  function toggleJustyoursModal(payload) {
    dispatch({
      type: TOGGLE_JUSTYOURS_MODAL,
      payload,
    });
  }
  function toggleYopModal(payload) {
    dispatch({
      type: TOGGLE_YOP_MODAL,
      payload,
    });
  }
  function toggleMortalMetacitiesModal(payload) {
    dispatch({
      type: TOGGLE_MORTAL_METACITIES,
      payload,
    });
  }
  function toggleKonstaModal(payload) {
    dispatch({
      type: TOGGLE_KONSTA_MODAL,
      payload,
    });
  }
  function toggleNftLootsModal(payload) {
    dispatch({
      type: TOGGLE_NFTLOOTS_MODAL,
      payload,
    });
  }
  function toggleChubcayModal(payload) {
    dispatch({
      type: TOGGLE_CHUBCAY_MODAL,
      payload,
    });
  }
  function setMaximizeContactsModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_CONTACTS_MODAL,
      payload,
    });
  }
  function setMaximizeAboutUsModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_ABOUTUS_MODAL,
      payload,
    });
  }
  function setMaximizeMinesweeperModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_MINESWEEPER_MODAL,
      payload,
    });
  }
  function setMaximizePartnersModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_PARTNERS_MODAL,
      payload,
    });
  }
  function setMaximizeOurServicesModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_OURSERVICES_MODAL,
      payload,
    });
  }
  function setMaximizePaintModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_PAINT_MODAL,
      payload,
    });
  }
  function setMaximizeTeamsModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_TEAMS_MODAL,
      payload,
    });
  }
  function setMaximizeTrashModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_TRASH_MODAL,
      payload,
    });
  }
  function setMaximizeProjectsModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_PROJECTS_MODAL,
      payload,
    });
  }
  function setMaximizeProjectCompassModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_COMPASS_PROJECT_MODAL,
      payload,
    });
  }
  function setMaximizeProjectYOPModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_YOP_PROJECT_MODAL,
      payload,
    });
  }
  function setMaximizeProjectThrupennyModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_THRUPENNY_PROJECT_MODAL,
      payload,
    });
  }
  function setMaximizeProjectMortalModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_MORTAL_PROJECT_MODAL,
      payload,
    });
  }
  function setMaximizeProjectMomentoModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_MOMENTO_PROJECT_MODAL,
      payload,
    });
  }
  function setMaximizeProjectKonstaModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_KONSTA_PROJECT_MODAL,
      payload,
    });
  }
  function setMaximizeProjectJustYoursModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_JUSTYOURS_PROJECT_MODAL,
      payload,
    });
  }
  function setMaximizeProjectGogoModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_GOGO_PROJECT_MODAL,
      payload,
    });
  }
  function setMaximizeProjectCoyoteModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_COYOTE_PROJECT_MODAL,
      payload,
    });
  }
  function setMaximizeProjectClovercoinModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_CLOVERCOIN_PROJECT_MODAL,
      payload,
    });
  }
  function setMaximizeProjectNftLootsModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_NFTLOOTS_MODAL,
      payload,
    });
  }
  function setMaximizeProjectChubcayModal(payload) {
    dispatch({
      type: SET_MAXIMIZE_CHUBCAY_MODAL,
      payload,
    });
  }

  const value = useMemo(
    () => ({
      state,
      actions: {
        setPreloader,
        toggleMenu,
        setActiveBg,
        toggleAboutModal,
        toggleContactUsModal,
        setSleepingMode,
        toggleTeamModal,
        setGlobalZindex,
        toggleServicesModal,
        toggleTrashModal,
        toggleMinesweeperModal,
        togglePaintModal,
        toggleAntivirusModal,
        setAllModalsClose,
        toggleSearchModal,
        toggleFooter,
        toggleProjectsModal,
        toggleWeb3CompassModal,
        toggleThrupennyModal,
        toggleCoyoteModal,
        toggleClovercoinModal,
        toggleGogofinanceModal,
        toggleMomentoModal,
        toggleJustyoursModal,
        toggleYopModal,
        toggleMortalMetacitiesModal,
        toggleKonstaModal,
        togglePartnersModal,
        toggleNftLootsModal,
        toggleChubcayModal,
        setMaximizeContactsModal,
        setMaximizeProjectsModal,
        setMaximizeProjectCompassModal,
        setMaximizeProjectYOPModal,
        setMaximizeProjectThrupennyModal,
        setMaximizeProjectMortalModal,
        setMaximizeProjectMomentoModal,
        setMaximizeProjectKonstaModal,
        setMaximizeProjectJustYoursModal,
        setMaximizeProjectGogoModal,
        setMaximizeProjectCoyoteModal,
        setMaximizeProjectClovercoinModal,
        setMaximizeAboutUsModal,
        setMaximizeMinesweeperModal,
        setMaximizePartnersModal,
        setMaximizeOurServicesModal,
        setMaximizePaintModal,
        setMaximizeTeamsModal,
        setMaximizeTrashModal,
        setUnMaximizeAllModals,
        setMaximizeProjectNftLootsModal,
        setMaximizeProjectChubcayModal,
      },
    }),
    [state]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default Provider;

Provider.propTypes = {
  children: element.isRequired,
};
