import React from 'react';

import AboutUs from '../../../../assets/images/icons/about_us.svg';
import Blog from '../../../../assets/images/icons/desktopIcons/blog.svg';
import ContactUs from '../../../../assets/images/icons/desktopIcons/contacts.svg';
import Projects from '../../../../assets/images/icons/desktopIcons/project.svg';
import Minesweeper from '../../../../assets/images/icons/minesweeper.svg';
import OurServices from '../../../../assets/images/icons/our_services.svg';
import Paint from '../../../../assets/images/icons/paint.svg';
import Partners from '../../../../assets/images/icons/partners.svg';
import Search from '../../../../assets/images/icons/Search_icon.svg';
import Team from '../../../../assets/images/icons/team_page.svg';

export default {
  data: [
    {
      icon: <Search />,
      machineName: 'search',
      name: 'Search',
      id: 1,
    },
    {
      icon: <AboutUs />,
      machineName: 'aboutUs',
      name: 'About Us',
      id: 2,
    },
    {
      icon: <Blog />,
      machineName: 'blog',
      name: 'Blog',
      id: 3,
    },
    {
      icon: <ContactUs />,
      machineName: 'contactUs',
      name: 'Contacts',
      id: 4,
    },
    {
      icon: <Projects />,
      machineName: 'projects',
      name: 'Projects',
      id: 5,
    },
    { icon: <Team />, machineName: 'team', name: 'Team', id: 6 },
    {
      icon: <OurServices />,
      machineName: 'service',
      name: 'Our Services',
      id: 7,
    },
    {
      icon: <Partners />,
      machineName: 'partners',
      name: 'Partners',
      id: 8,
    },
    { icon: <Paint />, machineName: 'paint', name: 'Paint', id: 9 },
    {
      icon: <Minesweeper />,
      machineName: 'minesweepeer',
      name: 'Minesweeper',
      id: 10,
    },
  ],
};
