const theme = {
  breakpoints: {
    xxl: 2560,
    xl: 1920,
    lg: 1440,
    md: 1024,
    sm: 768,
    xs: 440,
    default: 0,
    up(size) {
      return `@media (min-width: ${this[size]}px)`;
    },
    down(size) {
      return `@media (max-width: ${this[size]}px)`;
    },
  },
  palette: {
    primary: {
      main: '#000000',
      light: '#FFFFFF',
      dark: '#7E7E7E',
      darkLight: '#00FF00',
    },
    secondary: {
      main: '#353535',
      light: '#9F9F9F',
      dark: '#c0c0c0',
      darkLight: '#262626',
    },
    background: {
      main: '#111111',
      light: '#DFDFDF',
    },
    common: {
      main: '#B03EFB',
      light: '#3FD7EF',
      dark: '#E00000',
      darkLight: '#1478ca',
    },
  },
  font: {
    main: 'W95FA',
  },
  taskBarHeight: {
    xxl: 87,
    xl: 58,
    lg: 56,
    md: 50,
    xs: 44,
  },
};

export default theme;
