import React, { memo } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { string } from 'prop-types';
import { useTheme } from 'styled-components';

import ImageWrapper from './StyledBackground';
import getSources from '../../../helpers/background';

function Background({ activeBg }) {
  const { breakpoints } = useTheme();

  const data = useStaticQuery(graphql`
    query {
      f_1: allFile(
        filter: { relativeDirectory: { eq: "images/background/1" } }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
      f_2: allFile(
        filter: { relativeDirectory: { eq: "images/background/2" } }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const images = getSources(data[`f_${activeBg}`].edges, breakpoints);

  return (
    <ImageWrapper>
      <GatsbyImage
        onLoad={() => {
          const loadEvent = new CustomEvent('loadBackground');
          document.dispatchEvent(loadEvent);
        }}
        alt="block stars"
        image={images}
      />
    </ImageWrapper>
  );
}

Background.propTypes = {
  activeBg: string.isRequired,
};

export default memo(Background);
