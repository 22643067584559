import React, { useRef, useState } from 'react';

import Sound from '../../../../../assets/images/icons/sound.svg';
import SoundMute from '../../../../../assets/images/icons/soundMute.svg';
import tetrisSong from '../../../../../assets/sounds/tetris.mp3';

function Audio() {
  const [isPlaying, setPlaying] = useState(false);
  const audio = useRef();
  const handlePlaySound = () => {
    setPlaying(true);
    audio.current.play();
  };

  const handleMuteSound = () => {
    audio.current.pause();
    setPlaying(false);
  };

  return (
    <>
      {isPlaying ? (
        <Sound onClick={handleMuteSound} />
      ) : (
        <SoundMute onClick={handlePlaySound} />
      )}
      <audio ref={audio} loop preload="auto">
        <source src={tetrisSong} type="audio/mpeg" />
        <track kind="captions" />
      </audio>
    </>
  );
}

export default Audio;
