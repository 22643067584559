module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_@babel+core@7.12._jzflxleok5ec4bo2vl3kqh3pti/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gtag@1.0.13_gatsby@5.13.6_@types+webpack@4.41.38_babel-eslint@10.1.0_eslint@8.5_pkw2zmm5r3v2vxfa5h4eypzswm/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-RTPXVBFT3W","head":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.6_@types+webpack@4.41.38_babel-eslint@10.1.0_eslint@8.57.0__eslint-plugin-jest@24_6d4n7mu723ckpmwqtsqucxvfna/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
