import React, { memo, useEffect, useState } from 'react';

import { bool, func } from 'prop-types';

import Stars from './Stars';
import Wrapper from './StyledSleepingMode';

function SleepingMode({ isSleepingModeOn, setSleepingMode }) {
  const [isMouseMoving, setIsMouseMoving] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isMouseMoving) {
        setSleepingMode(true);
      }
    }, 1000000);

    const handleMouseMove = () => {
      setIsMouseMoving(true);
      setSleepingMode(false);
      clearTimeout(timer);
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(timer);
    };
  }, [isMouseMoving, setSleepingMode]);

  if (!isSleepingModeOn) {
    return null;
  }

  return (
    <Wrapper show={isSleepingModeOn}>
      <Stars />
    </Wrapper>
  );
}

export default memo(SleepingMode);

SleepingMode.propTypes = {
  isSleepingModeOn: bool.isRequired,
  setSleepingMode: func.isRequired,
};
