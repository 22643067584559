import React, { memo, useContext } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { bool } from 'prop-types';

import IconWrapper from './StyledIcon';
import Context from '../../../../store/context';

function IconsList({ isFooterToggled }) {
  const { actions } = useContext(Context);
  const { setActiveBg } = actions;

  const data = useStaticQuery(graphql`
    query FooterMiniIcons {
      allFile(filter: { relativeDirectory: { eq: "images/footer" } }) {
        edges {
          node {
            childImageSharp {
              id
              gatsbyImageData(layout: FIXED)
            }
            name
          }
        }
      }
    }
  `);

  const handleClick = (id) => {
    setActiveBg(id);
  };

  return (
    <>
      {data.allFile.edges.map(
        (item) =>
          isFooterToggled && (
            <IconWrapper
              key={item.node.childImageSharp.id}
              onClick={() => handleClick(item.node.name)}
            >
              <GatsbyImage
                key={item.id}
                image={item.node.childImageSharp.gatsbyImageData}
                alt="Mini background icon"
                className="icon"
              />
            </IconWrapper>
          )
      )}
    </>
  );
}

export default memo(IconsList);

IconsList.propTypes = {
  isFooterToggled: bool.isRequired,
};
