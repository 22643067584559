import React, { memo } from 'react';

import { func, bool } from 'prop-types';
import Clock from 'react-live-clock';

import Audio from './Audio';
import { Wrapper, SvgWrapper, Text } from './StyledToolBar';
import Antivirus from '../../../../assets/images/icons/antivirus.svg';

function ToolBar({ toggleAntivirusModal, isFooterToggled }) {
  const handleOpenAntivirusModal = () => {
    toggleAntivirusModal(true);
  };

  return (
    <Wrapper>
      {isFooterToggled && (
        <SvgWrapper onClick={handleOpenAntivirusModal}>
          <Antivirus />
        </SvgWrapper>
      )}
      <SvgWrapper>
        <Audio />
      </SvgWrapper>
      <Text>
        <Clock format="LT" ticking />
      </Text>
    </Wrapper>
  );
}

export default memo(ToolBar);

ToolBar.propTypes = {
  toggleAntivirusModal: func.isRequired,
  isFooterToggled: bool.isRequired,
};
